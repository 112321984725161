import axios from 'axios';
import { END_POINTS } from '../constants/Constant';

export const FETCH_DATA_REQUEST = 'FETCH_PLIST_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_PLIST_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_PLIST_FAILURE';

export const fetchDataRequest = () => ({
  type: FETCH_DATA_REQUEST,
});

export const fetchDataSuccess = (data) => ({
  type: FETCH_DATA_SUCCESS,
  payload: data,
});

export const fetchDataFailure = (error) => ({
  type: FETCH_DATA_FAILURE,
  payload: error,
});

export const fetchPropertyListData = (cityid, typeid, listingTypeid, pageno) => {
  return async (dispatch, getState) => {  // Add `getState` parameter here
    dispatch(fetchDataRequest());
    try {
      const response = await axios.post(END_POINTS.GET_FEATHER_PROPERTY_TYPE, {
        city: cityid,
        type: typeid,
        listingType: listingTypeid,
        page: pageno,
      });

      // // Append new data to existing list from state
      // const currentData = getState().propertyList.data?.data || []; // Ensure `getState` is used correctly
      
      // const newData = pageno > 1 ? [...currentData, ...response.data.data] : response.data.data;

      // dispatch(fetchDataSuccess({ ...response.data, data: newData }));
          // Safely access response data
          const newData = response?.data?.data || [];
          const totalPages = response?.data?.totalPages || 1; // Assuming API returns totalPages
    
          // Dispatch success with the fresh data for the current page
          dispatch(fetchDataSuccess({ 
            ...response.data, 
            data: newData,  // Replacing the data with the fresh page data
            totalPages,     // Pass totalPages to use in pagination
          }));
    } catch (error) {
      dispatch(fetchDataFailure(error.message));
    }
  };
};