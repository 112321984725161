import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk'; // Import the named export 'thunk'
import rootReducer from './reducers'; // Import your root reducer

const initialState = {

}; 
const middleware = [thunk]; 

const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(...middleware)
);

export default store;