import axios from 'axios';
import { END_POINTS } from '../constants/Constant';

export const FETCH_DATA_REQUEST = 'FETCH_SOLD_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_SOLD_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_SOLD_FAILURE';

export const fetchDataRequest = () => ({
  type: FETCH_DATA_REQUEST,
});

export const fetchDataSuccess = (data) => ({
  type: FETCH_DATA_SUCCESS,
  payload: data,
});

export const fetchDataFailure = (error) => ({
  type: FETCH_DATA_FAILURE,
  payload: error,
});

export const fetchSoldPropertyData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      const response = await axios.post(END_POINTS.GET_FEATHER_PROPERTY_TYPE, { isSoldOut: true, page: 1 });
      //console.log(response.data.data,"sold -------------");
      const filteredProperties = response.data?.data
        ?.filter(item => item.isSoldOut === true && item.isActive === true && item.isFeatured === true)
        .slice(0, 3);
      dispatch(fetchDataSuccess(filteredProperties));
    } catch (error) {
      dispatch(fetchDataFailure(error.message));
    }
  };
};