// import axios from 'axios';
// import { END_POINTS } from '../constants/Constant';

// export const FETCH_DATA_REQUEST = 'FETCH_BLOG_REQUEST';
// export const FETCH_DATA_SUCCESS = 'FETCH_BLOG_SUCCESS';
// export const FETCH_DATA_FAILURE = 'FETCH_BLOG_FAILURE';

// export const fetchDataRequest = () => ({
//   type: FETCH_DATA_REQUEST,
// });

// export const fetchDataSuccess = (data) => ({
//   type: FETCH_DATA_SUCCESS,
//   payload: data,
// });

// export const fetchDataFailure = (error) => ({
//   type: FETCH_DATA_FAILURE,
//   payload: error,
// });

// export const fetchBlogData = (page) => {
//   return async (dispatch) => {
//     dispatch(fetchDataRequest());
//     try {
//       const response = await axios.get(END_POINTS.GET_BLOGS+`?page=${page}`);
//       console.log(response.data,"------------- blog");
//       dispatch(fetchDataSuccess(response.data));
//     } catch (error) {
//       dispatch(fetchDataFailure(error.message));
//     }
//   };
// };
import axios from 'axios';
import { END_POINTS } from '../constants/Constant';

export const FETCH_DATA_REQUEST = 'FETCH_BLOG_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_BLOG_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_BLOG_FAILURE';

export const fetchDataRequest = () => ({
  type: FETCH_DATA_REQUEST,
});

export const fetchDataSuccess = (data, append) => ({
  type: FETCH_DATA_SUCCESS,
  payload: data,
  append,
});

export const fetchDataFailure = (error) => ({
  type: FETCH_DATA_FAILURE,
  payload: error,
});

export const    fetchBlogData = (page) => {
  return async (dispatch, getState) => {
    dispatch(fetchDataRequest());
    try {
      const response = await axios.get(END_POINTS.GET_BLOGS + `?page=${page}`);
      console.log(response,"89898--*-/-*84651621");
      
      const append = page > 1;  // Append only if we're on a subsequent page
      dispatch(fetchDataSuccess(response.data, append));
    } catch (error) {
      dispatch(fetchDataFailure(error.message));
    }
  };
};
