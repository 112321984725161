import {
  faBuilding,
  faChevronDown,
  faChevronUp,
  faEye,
  faInr,
  faLocationArrow,
  faLongArrowAltRight,
  faMapMarked,
  faMapMarker,
  faTextHeight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPropertyListData } from "../../actions/propertyListActions";
import { BASE_URL, s3imageUrl } from "../../constants/Constant";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import _debounce from "lodash/debounce";
import { Pagination } from "react-bootstrap";

const SearchResult = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [bannerImage, setBannerImage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedStates, setExpandedStates] = useState({});
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [isExpanded, setIsExpanded] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const cityData = useSelector((state) => state.city);
  const propertyTypeData = useSelector((state) => state.propertyType);
  const listingTypeData = useSelector((state) => state.listingType);
  const propertyListData = useSelector((state) => state.propertyList);
  const content = useSelector((state) => state.headerFoooter);
  const [cityValidationMsg, setCityValidationMsg] = useState("");
  let [city, setCity] = useState("");
  let [ptype, setPtype] = useState("");
  let [ltype, setLtype] = useState("");
  let [city1, setCity1] = useState("");
  let [ptype1, setPtype1] = useState("");
  let [ltype1, setLtype1] = useState("");
  let [faqs, setFaqs] = useState();

  const [pageContent, setPageContent] = useState("");
  const [staticContent, setStaticContent] = useState("");
  const titledec = `Looking for #PropertyType# for sale in #City#? Explore prime
                #PropertyType# in #City#’s most sought-after neighbourhoods.
                Find your dream home with excellent amenities and great
                investment potential.`;
  const getBanner = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/property-banner/get`, {
        headers: {},
      });
      setBannerImage(response.data?.data?.bannerImage);
    } catch (error) {
      console.error("Error fetching cities -->", error.response?.data || error);
    }
  };
  const fetchSearchContent = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/cms/search-content/get`);
      const result = await response.json();
      if (result.success) {
        setPageContent(result.data.description);
      }
    } catch (error) {
      console.error("Error getting search content:", error);
    }
  };
  const fetchFaq = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/cms/faq/get`);
      const result = await response.json();
      if (result.success) {
        setFaqs(result.data);
      }
    } catch (error) {
      console.error("Error getting faq content:", error);
    }
  };
  console.log(
    location.pathname.substring(location.pathname.lastIndexOf("/") + 1),
    "9999999999999999"
  );

  const fetchStaticContent = async () => {
    console.log("calling");

    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        url: location.pathname.substring(
          location.pathname.lastIndexOf("/") + 1
        ),
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL}/api/cms/static/getbyurl`,
        requestOptions
      );
      const result = await response.json();

      console.log(response, "*****************************");

      if (result.success) {
        console.log("Successfully fetched static content:", result.data);
        setStaticContent(result.data);
        // Add further processing of result.data if needed
      } else {
        setStaticContent();
      }
    } catch (error) {
      console.error("Error getting search content:", error);
    }
  };
  function getLastSegment(url) {
    const parts = url.split("/");
    return parts[parts.length - 1];
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const { propertyType, listingType, city } = extractDetails(
      getLastSegment(window.location.pathname)
    );
    console.log(propertyType, listingType, city, "from url");
    if (city) {
      const cityObj = cityData?.data?.filter((p) => p.name === city);

      console.log(cityObj, cityData, "type of city");
      if (cityObj.length > 0) {
        setCity(cityObj[0]);
        setCity1(cityObj[0]);

        localStorage.setItem("selectedCity", JSON.stringify(cityObj[0]));
      }
    }
    if (propertyType) {
      const lowercasedKeyword = propertyType?.toLowerCase();

      const typeObj = propertyTypeData.data?.filter((item) =>
        item.name?.toLowerCase()?.includes(lowercasedKeyword)
      );

      console.log(typeObj, "type of property");

      if (typeObj.length > 0) {
        setPtype(typeObj[0]);
        setPtype1(typeObj[0]);
        localStorage.setItem(
          "selectedPropertyType",
          JSON.stringify(typeObj[0])
        );
      }
    }
    if (listingType) {
      const cityObj = listingTypeData.data.filter(
        (p) => p.name === listingType
      );
      console.log(cityObj, "type of Ltyre");

      if (cityObj.length > 0) {
        setLtype(cityObj[0]);
        setLtype1(cityObj[0]);
        localStorage.setItem("selectedListingType", JSON.stringify(cityObj[0]));
      }
    }

    getBanner();
    fetchSearchContent();
    fetchFaq();
    fetchStaticContent();
    console.log(location.state, "this is state");
    setCityValidationMsg("");

    const selectedCity = localStorage.getItem("selectedCity");
    const selectedPropertyType = localStorage.getItem("selectedPropertyType");
    const selectedListingType = localStorage.getItem("selectedListingType");

    console.log(
      selectedCity,
      selectedPropertyType,
      selectedListingType,
      "<----------------------selected city"
    );

    const citySelect = document.querySelector('select[aria-label="city"]');
    const propertyTypeSelect = document.querySelector(
      'select[aria-label="p-type"]'
    );
    const listingTypeSelect = document.querySelector(
      'select[aria-label="l-type"]'
    );

    if (citySelect) {
      if (selectedCity === "") {
        citySelect.value = "";
      } else {
        citySelect.value = JSON.parse(selectedCity)?._id;

        setCity(JSON.parse(selectedCity));
        setCity1(JSON.parse(selectedCity));
      }
    }

    if (propertyTypeSelect) {
      if (selectedPropertyType === "") {
        propertyTypeSelect.value = "";
      } else {
        propertyTypeSelect.value = JSON.parse(selectedPropertyType)?._id;
        setPtype(JSON.parse(selectedPropertyType));
        setPtype1(JSON.parse(selectedPropertyType));
      }
    }

    if (listingTypeSelect) {
      if (selectedListingType === "") {
        listingTypeSelect.value = "";
      } else {
        listingTypeSelect.value = JSON.parse(selectedListingType)?._id;
        setLtype(JSON.parse(selectedListingType));
        setLtype1(JSON.parse(selectedListingType));
      }
    }

    dispatch(
      fetchPropertyListData(
        selectedCity === "" ? "" : JSON.parse(selectedCity)?._id,
        selectedPropertyType === ""
          ? ""
          : JSON.parse(selectedPropertyType)?._id,
        selectedListingType === "" ? "" : JSON.parse(selectedListingType)?._id,
        1
      )
    );
    setCurrentPage(1);
    if (
      !cityData.data.loading &&
      !propertyTypeData.data.loading &&
      !listingTypeData.data.loading
    ) {
      setIsLoading(false);
    }
  }, [dispatch, location.state, location.pathname]);

  function extractDetails(inputString) {
    const lowerCasedString = inputString.toLowerCase();

    const parts = lowerCasedString.split("-");

    const listingTypeIndex = parts.findIndex(
      (part) =>
        part === "for" &&
        (parts[parts.indexOf(part) + 1] === "sale" ||
          parts[parts.indexOf(part) + 1] === "rent")
    );

    let listingType = null;
    if (listingTypeIndex !== -1) {
      listingType = `for ${parts[listingTypeIndex + 1]}`;
    }

    let propertyType =
      listingTypeIndex !== -1
        ? parts.slice(0, listingTypeIndex).join(" ")
        : null;
    propertyType = propertyType
      ? propertyType.replace(/\//g, " ").trim()
      : null;

    let city =
      listingTypeIndex !== -1
        ? parts.slice(listingTypeIndex + 2).join(" ")
        : null;
    city = city ? city.replace(/\bin\b/g, "").trim() : null;

    return {
      propertyType: propertyType ? capitalize(propertyType) : null,
      listingType: listingType ? capitalize(listingType) : null,
      city: city ? capitalize(city) : null,
    };
  }

  function capitalize(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }
  const toggleExpand = (propertyId) => {
    setExpandedStates((prev) => ({
      ...prev,
      [propertyId]: !prev[propertyId],
    }));
  };
  const handleCityChange = (e) => {
    setCityValidationMsg("");
    const selectedCityId = e.target.value;
    if (selectedCityId === "") {
      localStorage.setItem("selectedCity", "");
    } else {
      const selectedCity = cityData.data.find(
        (city) => city._id === selectedCityId
      );
      localStorage.setItem("selectedCity", JSON.stringify(selectedCity));
      setCity(selectedCity);
    }
  };
  const handlePropertyTypeChange = (e) => {
    const selectedTypeId = e.target.value;
    if (selectedTypeId === "") {
      localStorage.setItem("selectedPropertyType", "");
    } else {
      const selectedCity = propertyTypeData.data.find(
        (p) => p._id === selectedTypeId
      );
      localStorage.setItem(
        "selectedPropertyType",
        JSON.stringify(selectedCity)
      );
      setPtype(selectedCity);
    }
  };
  const handleListingTypeChange = (e) => {
    const selectedLTypeId = e.target.value;
    if (selectedLTypeId === "") {
      localStorage.setItem("selectedListingType", "");
    } else {
      const selectedCity = listingTypeData.data.find(
        (l) => l._id === selectedLTypeId
      );
      localStorage.setItem("selectedListingType", JSON.stringify(selectedCity));
      setLtype(selectedCity);
    }
  };
  const handleWhatsAppClick = (property) => {
    const message = `I am interested in booking a viewing for the property: ${property.title}`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${
      content.data.headerPhone
    }&text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, "_blank");
  };
  const handleSearch = () => {
    const selectedCity = localStorage.getItem("selectedCity");
    if (!selectedCity) {
      setCityValidationMsg("Please select a city.");
      return;
    }
    const selectedPropertyType = localStorage.getItem("selectedPropertyType");
    const selectedListingType = localStorage.getItem("selectedListingType");
    setCity1(selectedCity === "" ? "" : JSON.parse(selectedCity));
    setPtype1(
      selectedPropertyType === "" ? "" : JSON.parse(selectedPropertyType)
    );
    setLtype1(
      selectedListingType === "" ? "" : JSON.parse(selectedListingType)
    );

    dispatch(
      fetchPropertyListData(
        selectedCity === "" ? "" : JSON.parse(selectedCity)?._id,
        selectedPropertyType === ""
          ? ""
          : JSON.parse(selectedPropertyType)?._id,
        selectedListingType === "" ? "" : JSON.parse(selectedListingType)?._id,
        1
      )
    );
    setCurrentPage(1);

    const formattedPtype =
      selectedPropertyType === ""
        ? ""
        : JSON.parse(selectedPropertyType)
        ? JSON.parse(selectedPropertyType)
            ?.name.replace(/\s+/g, "-")
            .toLowerCase()
        : "";
    const formattedLtype =
      selectedListingType === ""
        ? ""
        : JSON.parse(selectedListingType)
        ? JSON.parse(selectedListingType)
            ?.name.replace(/\s+/g, "-")
            .toLowerCase()
        : "";
    const formattedCity =
      selectedCity === ""
        ? ""
        : JSON.parse(selectedCity)
        ? JSON.parse(selectedCity)?.name.replace(/\s+/g, "-").toLowerCase()
        : "";
    console.log(
      typeof formattedPtype,
      formattedLtype,
      formattedCity,
      "<---------xxxxxxxxxxxxxxxx"
    );

    const dynamicUrl = `/properties/${
      formattedPtype === "" ? "properties" : formattedPtype
    }${formattedLtype ? `-${formattedLtype}` : ""}${
      formattedCity ? "-in-" + formattedCity : ""
    }`;
    navigate(dynamicUrl, { replace: true });
  };
  const handleViewMore = (property) => {
    navigate(`/property/${property?.url}`, { state: property });
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      const selectedCity = localStorage.getItem("selectedCity");
      const selectedPropertyType = localStorage.getItem("selectedPropertyType");
      const selectedListingType = localStorage.getItem("selectedListingType");

      dispatch(
        fetchPropertyListData(
          selectedCity === "" ? "" : JSON.parse(selectedCity)?._id,
          selectedPropertyType === ""
            ? ""
            : JSON.parse(selectedPropertyType)?._id,
          selectedListingType === ""
            ? ""
            : JSON.parse(selectedListingType)?._id,
          pageNumber
        )
      );
    }
    window.scrollTo(0, 0);
  };
  const PropertySkeleton = () => (
    <div className="row py-2 py-sm-3">
      <div className="col-12">
        <div className="bg-light text-capitalize line-hight-1 h-100 rounded search-result-box">
          <div className="row">
            <div className="col-md-4 col-lg-4 col-xl-4">
              <div className="overflow-hidden position-relative rounded search-result-img skeleton-image"></div>
            </div>
            <div className="col-md-8 col-lg-8 col-xl-8">
              <div className="p-3 p-sm-4 py-md-3 px-md-2 p-lg-4 ps-md-0 ps-lg-2 ps-xxl-4">
                <div className="skeleton-title mb-2"></div>
                <div className="skeleton-row my-2"></div>
                <div className="skeleton-row my-2"></div>
                <div className="skeleton-row my-2"></div>
                <div className="skeleton-button mt-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const fetchMoreProperties = () => {
    if (isLoadingMore) return; // Prevent multiple fetches
    setIsLoadingMore(true);
    const selectedCity = localStorage.getItem("selectedCity");
    const selectedPropertyType = localStorage.getItem("selectedPropertyType");
    const selectedListingType = localStorage.getItem("selectedListingType");
    dispatch(
      fetchPropertyListData(
        selectedCity === "" ? "" : JSON.parse(selectedCity)?._id,
        selectedPropertyType === ""
          ? ""
          : JSON.parse(selectedPropertyType)?._id,
        selectedListingType === "" ? "" : JSON.parse(selectedListingType)?._id,
        currentPage + 1
      )
    ).finally(() => {
      setCurrentPage((prevPage) => prevPage + 1); // Update to next page
      setIsLoadingMore(false);
    });
  };
  // const faqs = [
  //   {
  //     question: "How do I get started with your service?",
  //     answer:
  //       "Getting started is easy! Simply create an account on our platform and follow the step-by-step guide. Our onboarding process is designed to be straightforward and user-friendly.",
  //   },
  //   {
  //     question: "What payment methods do you accept?",
  //     answer:
  //       "We accept all major credit cards including Visa, MasterCard, and American Express. We also support PayPal and direct bank transfers for your convenience.",
  //   },
  //   {
  //     question: "How long does shipping usually take?",
  //     answer:
  //       "Standard shipping typically takes 3-5 business days within the continental US. International shipping can take 7-14 business days depending on the destination.",
  //   },
  //   {
  //     question: "What is your refund policy?",
  //     answer:
  //       "We offer a 30-day money-back guarantee on all our products. If you're not satisfied with your purchase, simply contact our customer service team to initiate the refund process.",
  //   },
  //   {
  //     question: "Do you offer customer support?",
  //     answer:
  //       "Yes, we provide 24/7 customer support through email, live chat, and phone. Our dedicated team is always ready to help you with any questions or concerns.",
  //   },
  // ];
  // const handleScroll = useCallback(
  //   _debounce(() => {
  //     console.log(window.innerHeight, window.scrollY, document.body.offsetHeight, "**********************");

  //     if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 && !isLoadingMore) {
  //       fetchMoreProperties();
  //     }
  //   }, 1000),
  //   [isLoadingMore]
  // );
  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [handleScroll]);

  useEffect(() => {
    console.log(propertyListData.data.data, "<------------------------------");
    if (propertyListData.data.totalPages) {
      console.log(
        propertyListData.data.totalPages,
        "<------------------------------"
      );
      setTotalPages(propertyListData.data.totalPages || 1);
    }
  }, [propertyListData]);
  if (
    cityData.data.loading &&
    propertyTypeData.data.loading &&
    listingTypeData.data.loading
  ) {
    return <p>Loading..........</p>;
  }
  // Pagination Component
  const renderPagination = () => {
    const pageItems = [];

    // Create a range of page numbers
    for (let number = 1; number <= totalPages; number++) {
      pageItems.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    return (
      <Pagination className="justify-content-center mt-4 pagination-lg">
        {/* Previous Button */}
        <Pagination.Prev
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Pagination.Prev>

        {/* Page Numbers */}
        {pageItems}

        {/* Next Button */}
        <Pagination.Next
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </Pagination.Next>
      </Pagination>
    );
  };

  return (
    <>
      <section
        className="inner-banner aos-init position-relative aos-animate"
        data-aos="fade-up"
        // style={{ background: `url(${BASE_URL}/${bannerImage}) center`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}
      >
        <img src={`${BASE_URL}/${bannerImage}`} />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-8 col-md-8 mx-auto">
              <div className="text-center">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-3 font-size-14 justify-content-center">
                    <li className="breadcrumb-item">
                      <Link
                        to="/"
                        className="font-weight-bold text-danger text-decoration-none"
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faLongArrowAltRight}
                        className="text-white mx-2 mx-md-3"
                      />
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      Properties
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
          <div className="form-bg bg-white p-4 mt-4">
            <div className="row genuine-university row-cols-1 row-cols-sm-2 row-cols-md-4 g-3 g-lg-3">
              <div className="col mb-0 select-drop-col">
                {cityData.data.loading ? (
                  <div>Loading...</div>
                ) : cityData.data.error ? (
                  <div>Error: {cityData.error}</div>
                ) : (
                  <select
                    className="form-select form-control rounded-pill border-0 bg-light ps-4 font-weight-normal"
                    aria-label="city"
                    // value={localStorage.getItem('selectedCity')}
                    value={city._id}
                    onChange={handleCityChange}
                    // onChange={(e)=>//console.log(e.target.value,)}
                  >
                    <option value="">City</option>
                    {cityData.data.map((city, index) => (
                      <option key={index} value={city._id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                )}
                {cityValidationMsg && (
                  <div className="text-danger mt-1">{cityValidationMsg}</div>
                )}
              </div>
              <div className="col mb-0 select-drop-col">
                {propertyTypeData.data.loading ? (
                  <div>Loading...</div>
                ) : propertyTypeData.data.error ? (
                  <div>Error: {propertyTypeData.error}</div>
                ) : (
                  <select
                    className="form-select form-control rounded-pill border-0 bg-light ps-4 font-weight-normal"
                    aria-label="p-type"
                    onChange={handlePropertyTypeChange}
                    value={ptype._id}
                  >
                    <option value="">Property Type</option>
                    {propertyTypeData.data.map((type, index) => (
                      <option key={index} value={type._id}>
                        {type.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <div className="col mb-0 select-drop-col">
                {listingTypeData.data.loading ? (
                  <div>Loading...</div>
                ) : listingTypeData.data.error ? (
                  <div className="text-danger">
                    Error: {listingTypeData.error}
                  </div>
                ) : (
                  <select
                    className="form-select form-control rounded-pill border-0 bg-light ps-4 font-weight-normal"
                    aria-label="l-type"
                    onChange={handleListingTypeChange}
                    value={ltype._id}
                  >
                    <option value="">Buy/Rent</option>
                    {listingTypeData.data.map((ltype, index) => (
                      <option key={index} value={ltype._id}>
                        {ltype.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>

              <div className="col mb-0 search-col">
                <button
                  type="button"
                  onClick={handleSearch}
                  className="btn btn-danger w-100 font-weight-bold font-size-16 rounded-pill py-2"
                >
                  <span className="d-block py-0 px-3">
                    Search Properties
                    <FontAwesomeIcon
                      icon={faLongArrowAltRight}
                      className="ms-2"
                    />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="homepad bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 className="text-dark font-weight-bold font-size-28 mb-3 mb-lg-4 line-hight-2">
                {ptype1.name || "All Properties"} {ltype1 ? ltype1.name : ""}{" "}
                {city1 ? "in " + city1.name : ""}
              </h1>
              <p className="search-p">
                {/* {`Looking for ${ptype1.name} for sale in ${city1.name}? Explore prime
                 ${ptype1.name} in ${city1.name}’s most sought-after neighbourhoods.
                Find your dream home with excellent amenities and great
                investment potential.`} */}
                {ptype1.name &&
                  ltype1.name &&
                  city1.name &&
                  pageContent
                    .replaceAll("#PROPERTYTYPE#", ptype1.name)
                    .replaceAll("#LISTINGTYPE#", ltype1.name)
                    .replaceAll("#CITY#", city1.name)}
                {/* <p className="search-p">
                {
                  `Looking for #PropertyType# for sale in #City#? Explore prime
                #PropertyType# in #City#’s most sought-after neighbourhoods.
                Find your dream home with excellent amenities and great
                investment potential.`
                }
                
              </p> */}
              </p>
            </div>
          </div>

          {propertyListData.data?.data?.loading ? (
            <>
              {Array(3)
                .fill()
                .map((_, index) => (
                  <PropertySkeleton key={index} />
                ))}
            </>
          ) : propertyListData?.data?.data?.filter(
              (property) => !property.isSoldOut
            ).length === 0 ? (
            <div>No properties found</div>
          ) : (
            propertyListData.data?.data
              ?.filter((property) => !property.isSoldOut)
              ?.map((property, index) => (
                <div className="row py-2 py-sm-3">
                  <div className="col-12">
                    <div className="bg-light text-capitalize line-hight-1 h-100 rounded search-result-box">
                      <div className="row">
                        <div className="col-md-4 col-lg-4 col-xl-4">
                          <div className="overflow-hidden position-relative rounded search-result-img">
                            <a
                              href="javascript:void(0)"
                              onClick={() => handleViewMore(property)}
                              className="search-resultimg"
                            >
                              <img
                                src={s3imageUrl + "/" + property.images[0]}
                                className="img-fluid rounded w-100 list-img"
                              />
                              {/* <img src={BASE_URL + '/' + property.images[0]} className="img-fluid rounded w-100 list-img" /> */}
                            </a>
                            <div className="properties-lable d-flex">
                              {property.listingType.name === "For Rent" ? (
                                ""
                              ) : (
                                <div className="tag-for font-weight-medium me-2">
                                  {property.type.name}
                                </div>
                              )}
                              {property.isSoldOut ? (
                                <div className="tag-for  font-weight-medium">
                                  Sold
                                </div>
                              ) : (
                                <div className="for-sale font-weight-medium">
                                  {property.listingType.name}
                                </div>
                              )}
                            </div>
                            {property.isFeatured && (
                              <div className="featured-ribbon">
                                <span>Featured</span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-8 col-lg-8 col-xl-8">
                          <div className="p-3 p-sm-4 py-md-3 px-md-2 p-lg-4 ps-md-0 ps-lg-2 ps-xxl-4">
                            <h2 className="mb-0 pb-lg-2 line-hight-1 pt-xxl-3">
                              <a
                                className="font-size-20 text-dark font-weight-semibold"
                                href="javascript:void(0)"
                                onClick={() => handleViewMore(property)}
                              >
                                {property.title}
                              </a>
                            </h2>
                            <div className="row">
                              <div className="col-lg-11 col-xl-9">
                                <div className="d-flex justify-content-between my-3 py-lg-2">
                                  <div className="text-secondary font-size-16 d-sm-flex px-1 ps-0">
                                    <div className="me-sm-2 me-lg-3 mb-2 mb-sm-0">
                                      <FontAwesomeIcon
                                        icon={faMapMarked}
                                        className="text-danger search-result-icon"
                                        style={{ fontSize: "34px" }}
                                      />
                                    </div>
                                    <div>
                                      <h6 className="font-weight-bold font-size-18 mb-1 mb-lg-2 text-dark line-hight-1">
                                        Location:
                                      </h6>
                                      {property.city.name}
                                    </div>
                                  </div>
                                  <div className="text-secondary font-size-16 d-sm-flex px-1">
                                    <div className="me-sm-2 me-lg-3 mb-2 mb-sm-0">
                                      <FontAwesomeIcon
                                        icon={faTextHeight}
                                        className="text-danger pt-md-1 search-result-icon"
                                        style={{ fontSize: "34px" }}
                                      />
                                    </div>
                                    <div>
                                      <h6 className="font-weight-bold font-size-18 mb-1 mb-lg-2 text-dark line-hight-1">
                                        Size:
                                      </h6>
                                      {property.size}
                                    </div>
                                  </div>
                                  <div className="text-secondary font-size-16 d-sm-flex px-1">
                                    <div className="me-sm-2 me-lg-3 mb-2 mb-sm-0">
                                      <FontAwesomeIcon
                                        icon={faInr}
                                        className="text-danger pt-md-1 search-result-icon"
                                        style={{ fontSize: "34px" }}
                                      />
                                    </div>
                                    <div>
                                      <h6 className="font-weight-bold font-size-18 mb-1 mb-lg-2 text-dark line-hight-1">
                                        {property.listingType.name ===
                                        "For Rent"
                                          ? "Rent"
                                          : "Price"}
                                        :
                                      </h6>
                                      {property.price}
                                    </div>
                                  </div>
                                  <div className="text-secondary font-size-16 d-sm-flex px-1">
                                    <div className="me-3">
                                      <FontAwesomeIcon
                                        icon={faLocationArrow}
                                        className="text-danger pt-md-1 search-result-icon"
                                        style={{ fontSize: "34px" }}
                                      />
                                    </div>
                                    <div>
                                      <h6 className="font-weight-bold font-size-18 mb-1 text-dark line-hight-1">
                                        Area:
                                      </h6>
                                      <span className="line-hight-2">
                                        {property.location.name}{" "}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="text-secondary font-size-16 d-flex align-items-center pt-xxl-2">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: property.description,
                                }}
                              />
                            </div> */}
                            {/* <div className="relative d-flex align-items-center">
                              <div
                                className={`text-secondary font-size-16 cc ${
                                  !isExpanded ? "line-clamp" : ""
                                }`}
                                dangerouslySetInnerHTML={{
                                  __html: property.sortDescription,
                                }}
                              />

                              {isExpanded ? (
                                <>
                                  <FontAwesomeIcon
                                    icon={faChevronUp}
                                    onClick={() => setIsExpanded(!isExpanded)}
                                    className="h-4 w-4"
                                  />
                                </>
                              ) : (
                                <>
                                  <FontAwesomeIcon
                                    icon={faChevronDown}
                                    onClick={() => setIsExpanded(!isExpanded)}
                                    className="h-4 w-4"
                                  />
                                </>
                              )}
                            </div> */}
                            <div className="relative d-flex align-items-center">
                              <div
                                className={`text-secondary font-size-16 cc ${
                                  !expandedStates[property._id]
                                    ? "line-clamp"
                                    : ""
                                }`}
                                style={{
                                  display: "-webkit-box",
                                  WebkitLineClamp: expandedStates[property._id]
                                    ? "unset"
                                    : "3",
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: property.sortDescription,
                                }}
                              />
                              <div
                                onClick={() => toggleExpand(property._id)}
                                className="cursor-pointer ml-2"
                              >
                                <FontAwesomeIcon
                                  icon={
                                    expandedStates[property._id]
                                      ? faChevronUp
                                      : faChevronDown
                                  }
                                  className="h-4 w-4"
                                />
                              </div>
                            </div>
                            <div className="d-flex mt-3 mt-md-4 properties-btn pt-2 pt-md-0 pt-xxl-2 pb-1 pb-md-0">
                              <div>
                                <button
                                  onClick={() => handleWhatsAppClick(property)}
                                  className="btn btn-whatapp font-weight-semibold font-size-16 rounded-pill py-2 me-3"
                                >
                                  <span className="d-block py-0 px-1 px-xl-2">
                                    <FontAwesomeIcon
                                      icon={faWhatsapp}
                                      className="me-1 me-xl-2"
                                    />
                                    Book Viewing
                                  </span>
                                </button>
                              </div>
                              <div>
                                <button
                                  className="btn btn-light font-weight-semibold font-size-16 rounded-pill py-2"
                                  onClick={() => handleViewMore(property)}
                                >
                                  <span className="d-block py-0 px-1 px-xl-2">
                                    <FontAwesomeIcon
                                      icon={faEye}
                                      className="me-1 me-xl-2"
                                    />
                                    View More
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
          )}
          {isLoadingMore && (
            <>
              {Array(3)
                .fill()
                .map((_, index) => (
                  <PropertySkeleton key={index} />
                ))}
            </>
          )}

          {propertyListData.data?.data?.length > 0 && renderPagination()}
        </div>
      </section>
      {staticContent && (
        <section className="homepad bg-light">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="text-dark font-weight-bold font-size-28 mb-3 mb-lg-4 line-hight-2 text-capitalize">
                  {staticContent?.title}
                </h3>
              </div>
              <div className="col-md-6">
                <div
                  className="search-p"
                  dangerouslySetInnerHTML={{
                    __html: staticContent.content,
                  }}
                />
              </div>
              <div className="col-md-6">
                <img
                  src={BASE_URL + "/public" + staticContent.image}
                  alt={BASE_URL + staticContent.title}
                  className="w-100 rounded object-cover"
                />
              </div>
            </div>
          </div>
        </section>
      )}
      {ptype1.name && (
        <section className="homepad bg-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="text-dark font-weight-bold font-size-28 mb-3 mb-lg-4 line-hight-2 text-capitalize">
                  Frequently Asked Questions
                </h3>
              </div>
              <div className="col-md-12">
                {faqs?.map((faq, index) => (
                  <div key={index} className="faq-item">
                    <div className="faq-answer-wrapperq">
                      <span>Q.</span>
                      <h4 className="faq-question">
                        {faq.question
                          .replaceAll("#PROPERTYTYPE#", ptype1.name)
                          .replaceAll("#LISTINGTYPE#", ltype1.name)
                          .replaceAll("#CITY#", city1.name)}
                      </h4>
                    </div>
                    <div className="faq-answer-wrapper">
                      <span>A.</span>
                      <p className="faq-answer mb-0">
                        {faq.answer
                          .replaceAll("#PROPERTYTYPE#", ptype1.name)
                          .replaceAll("#LISTINGTYPE#", ltype1.name)
                          .replaceAll("#CITY#", city1.name)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default SearchResult;
