import { faCheckCircle, faLongArrowAltRight, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../constants/Constant';

const Team = () => {
  const about = useSelector((state) => state.about);
  const team = useSelector((state) => state.team);

  return (
    <>
      <section className="homepad bg-light">
        <div className="container position-relative">
          <div className="row mb-3 mb-lg-0 mb-lg-4">
            <div className="col-md-9 col-lg-8 col-xl-7 mx-auto text-center">
              <h2 className="text-dark font-weight-bold font-size-50 mb-4 text-capitalize line-hight-1">{about.data.teamTitle}</h2>
              <p className="font-size-18 text-muted line-hight-3 mb-0 font-weight-normal">{about.data.teamDescription}</p>
            </div>
          </div>
          <div className="row justify-content-center">
            {team.data.loading ? (
              <div>Loading...</div>
            ) : team.data.error ? (
              <div>Error: {team.error}</div>
            ) : (
              team.data.map((t, index) => (
                <div className="col-12 col-sm-6 col-md-6 col-lg-3 mt-4">
                  <div className="bg-white rounded shadow-sm">
                    <div className="">
                      <img src=  {BASE_URL+'/'+t.image} className="img-fluid rounded w-100 t-img" />
                    </div>
                    <div className="p-4 text-center">
                      <h3 className="mb-1 font-size-22 text-danger font-weight-semibold">{t.name}</h3>
                      <h4 className="pt-2 pt-xxl-2 mb-0 font-size-16 text-secondary font-weight-normal line-hight-1">
                      {t.designation}
                      </h4>
                    </div>
                  </div>
                </div>
              ))
            )}
            {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 mt-4">
              <div className="bg-white rounded shadow-sm">
                <div className="">
                  <img src="images/team-img-01-650x800.jpg" className="img-fluid rounded w-100" />
                </div>
                <div className="p-4 text-center">
                  <h3 className="mb-1 font-size-22 text-danger font-weight-semibold">Ambert Daniel </h3>
                  <h4 className="pt-2 pt-xxl-2 mb-0 font-size-16 text-secondary font-weight-normal line-hight-1">
                    CEO &amp; Founder
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-3 mt-4">
              <div className="bg-white rounded shadow-sm">
                <div className="">
                  <img src="images/team-img-02-650x800.jpg" className="img-fluid rounded w-100" />
                </div>
                <div className="p-4 text-center">
                  <h3 className="mb-1 font-size-22 text-danger font-weight-semibold">Milano Digits </h3>
                  <h4 className="pt-2 pt-xxl-2 mb-0 font-size-16 text-secondary font-weight-normal line-hight-1">
                    Network Engineer
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-3 mt-4">
              <div className="bg-white rounded shadow-sm">
                <div className="">
                  <img src="images/team-img-03-650x800.jpg" className="img-fluid rounded w-100" />
                </div>
                <div className="p-4 text-center">
                  <h3 className="mb-1 font-size-22 text-danger font-weight-semibold">Daniel Smith </h3>
                  <h4 className="pt-2 pt-xxl-2 mb-0 font-size-16 text-secondary font-weight-normal line-hight-1">
                    Business Eng.
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 mt-4">
              <div className="bg-white rounded shadow-sm">
                <div className="">
                  <img src="images/team-img-04-650x800.jpg" className="img-fluid rounded w-100" />
                </div>
                <div className="p-4 text-center">
                  <h3 className="mb-1 font-size-22 text-danger font-weight-semibold">Ronald Richards </h3>
                  <h4 className="pt-2 pt-xxl-2 mb-0 font-size-16 text-secondary font-weight-normal line-hight-1">
                    Marketing
                  </h4>
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </section>
    </>
  )
}

export default Team