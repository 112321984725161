import { faLongArrowAltRight, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux';
import { BASE_URL, s3imageUrl } from '../../constants/Constant';
import { useNavigate } from 'react-router-dom';

const RecentlySold = () => {
  const navigate = useNavigate();
  const SoldList = useSelector((state) => state.soldProperty);
  console.log(SoldList,"----------------------------");
  const home = useSelector((state) => state.home);
  const handleViewMore = (property) => {
    navigate(`/property/${property.url}`, { state: property });
  };
  return (
    <>
      <section className="homepad bg-white">
        <div className="container">
          <div className="row mb-3 mb-lg-0 mb-lg-4">
            <div className="col-md-9 col-lg-8 col-xl-7 mx-auto text-center">
              <h2 className="text-dark font-weight-bold font-size-50 mb-4 text-capitalize line-hight-2">{home.data.soldTitle}</h2>
              <p className="font-size-18 text-muted line-hight-3 mb-0 font-weight-normal">{home.data.soldDescription}</p>
            </div>
          </div>
          <div className="row">
            {SoldList.loading ? (
              <div>Loading...</div>
            ) : SoldList.error ? (
              <div>Error: {SoldList.error}</div>
            ) : (
              SoldList.data.map((sold, index) => (
                <div className="col-12 col-md-6 col-lg-4 mt-3 mt-lg-4 properties-box">
                  <div className="bg-light text-capitalize line-hight-1 h-100 rounded p-0">
                    <div className="overflow-hidden position-relative rounded img-pro">
                      <a href="javascript:void(0)"
                      // onClick={() => handleViewMore(sold)}
                      >

                        {/* <img src={BASE_URL + '/' + sold.images[0]} className="img-fluid rounded w-100" /> */}
                        <img src={s3imageUrl + '/' + sold.images[0]} className="img-fluid rounded w-100" />

                      </a>
                      {
                        sold.isSoldOut
                          ?
                          <div className="properties-lable"><div className="tag-for font-weight-medium">Sold</div></div>
                          :
                          <div className="properties-lable"><div className="for-sale font-weight-medium">{sold.listingType.name}</div></div>

                      }


                    </div>
                    <div className="p-4">
                      <h3 className="mb-0"><a className="font-size-22 text-dark font-weight-semibold" href="javascript:void(0)"
                      // onClick={() => handleViewMore(sold)}
                      >{sold.title}</a></h3>
                      <div className="d-flex justify-content-between my-2 py-1">
                        <div className="text-secondary font-size-15">
                          <h6 className="font-weight-medium font-size-16 mb-1 text-dark">Location:</h6>{sold.city.name}
                        </div>
                        <div className="text-secondary font-size-15 px-2">
                          <h6 className="font-weight-medium font-size-16 mb-1 text-dark">Size:</h6>{sold.size}
                        </div>
                        <div className="text-secondary font-size-15">
                          <h6 className="font-weight-medium font-size-16 mb-1 text-dark">Price:</h6>{sold.price}
                        </div>
                      </div>
                      <div className="text-secondary font-size-15 d-flex align-items-center ">
                        <div className="text-secondary font-size-15">
                          <h6 className="font-weight-medium font-size-16 mb-1 text-dark">Area:</h6>{sold.location.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}

          </div>

        </div>
      </section>

    </>
  )
}

export default RecentlySold