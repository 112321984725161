import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../constants/Constant';

const Header = ({ active }) => {
  const content = useSelector((state) => state.headerFoooter);


  return (
    <>
      <header className="fixed-top bg-white header-top">
        <nav className="navbar navbar-expand-lg navbar-light py-3 font-size-15 font-weight-normal" aria-label="Fifth navbar example">
          <div className="container">
          <a className="navbar-brand logo-header me-0 me-sm-2 me-md-3" href="/">
  <img src={BASE_URL + '/' + content.data.logo} className="img-fluid" alt="Logo" />
</a>
            {/* <Link className="navbar-brand logo-header me-0 me-sm-2 me-md-3" to="/"><img src={BASE_URL + '/' + content.data.logo} className="img-fluid" /></Link> */}
            <span className="d-lg-none ms-auto me-2">
              <div className="company-info-item d-flex align-items-center company-call">
                <div className="header-icon me-2">
                  <img src="images/phone.png" alt="" />
                </div>
                <div className="header-info">
                  <p className="mb-0 mb-md-1 text-danger font-weight-medium font-size-14">Call us now!</p>
                  <h6 className="font-weight-semibold font-size-20 mb-0">{content.data.headerPhone}</h6>
                </div>
              </div>
            </span>
            <button className="navbar-toggler p-0 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample05" aria-controls="navbarsExample05" aria-expanded="false" aria-label="Toggle navigation">
              <img src="images/menu-list.png" className="img-fluid" />
            </button>

            <div className="collapse navbar-collapse" id="navbarsExample05">
              <ul className="navbar-nav mx-auto mb-0 mb-lg-0">
                <li className="nav-item">
                  <Link className={`nav-link ${active === 'home' ? 'active' : ''}`} aria-current="page" to="/">Home</Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link ${active === 'list' ? 'active' : ''}`} aria-current="page" to='/properties/all'>Hot Properties</Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link ${active === 'about' ? 'active' : ''}`} aria-current="page" to="/about-us">About Us</Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link ${active === 'blogs' ? 'active' : ''}`} aria-current="page" to="/blogs">Blogs</Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link ${active === 'testimonial' ? 'active' : ''}`} aria-current="page" to="/testimonials">Testimonials</Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link ${active === 'contact' ? 'active' : ''}`} aria-current="page" to="/contact-us">Contact Us</Link>
                </li>


              </ul>
              {/* <div className="d-none d-lg-flex">
                <div className="company-info-item d-flex align-items-center">
                  <div className="header-icon me-2 me-xl-3">
                    <img src="images/phone.png" alt="" />
                  </div>
                  <div className="header-info">
                    <p className="mb-1 text-danger font-weight-medium font-size-14">Call us now!</p>
                    <h6 className="font-weight-semibold font-size-20 mb-0">{content.data.headerPhone}</h6>
                  </div>
                </div>
              </div> */}
              <div className="d-none d-lg-flex">
                <div className="company-info-item d-flex align-items-center">
                  <div className="header-icon me-2 me-xl-3">
                    <img src="/images/phone.png" alt="Phone Icon" />
                  </div>
                  <div className="header-info">
                    <p className="mb-1 text-danger font-weight-medium font-size-14">Call us now!</p>
                    <h6 className="font-weight-semibold font-size-20 mb-0">
                      <a href={`tel:${content.data.headerPhone}`} className="text-decoration-none text-dark">
                        {content.data.headerPhone}
                      </a>
                    </h6>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </nav>
      </header>
    </>
  )
}

export default Header