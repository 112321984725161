import { faBuilding, faHandshake, faHome, faLongArrowAltRight, faMoneyBill, faMoneyBill1Wave, faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../constants/Constant';
import { Link } from 'react-router-dom';
import ReactStars from 'react-stars'
import { fetchTestimonialData } from '../../actions/testimonialActions';

const Testimonials = ({ ispage, showAll }) => {
  const dispatch = useDispatch()
  const home = useSelector((state) => state.home);
  const testimonial = useSelector((state) => state.testimonial);


  useEffect(() => {

    dispatch(fetchTestimonialData());

  }, [dispatch]);

  return (
    <>
      <section className="homepad bg-white">
        <div className="container">
          {
            !ispage && <div className="row">
              <div className="col-md-7 mx-auto text-center">
                <h5 className="text-danger font-weight-semibold text-uppercase font-size-20 mb-1 line-hight-3 findyour">{home.data.testimonialSubtitle}</h5>
                <h2 className="text-dark font-weight-bold font-size-50 mb-0 line-hight-3">{home.data.testimonialTitle}</h2>
              </div>
            </div>
          }

          <div className="row customer-reviews">

            {testimonial.data.loading ? (
              <div>Loading...</div>
            ) : testimonial.data.error ? (
              <div>Error: {testimonial.error}</div>
            ) : (
              // testimonial.data?.map((t, index) => (
              (showAll ? testimonial.data : testimonial.data?.slice(0, 6))?.map((t, index) => (
                <div key={index} className="item col-12 col-md-6 col-lg-4 mt-3 mt-lg-4">
                  <div className="customer-pad position-relative">
                    <div className="quote position-absolute p-2 rounded-circle d-flex justify-content-center align-items-center"><FontAwesomeIcon icon={faQuoteLeft} className='text-white' /></div>
                    <div className="p-4 text-center bg-light rounded min-h-testiminial">
                      <div className="customer-img rounded-circle mx-auto">
                        <img src={BASE_URL + '/' + t.image} className="img-fluid rounded-circle testimonial-img" />
                      </div>
                      <p className="font-size-16 text-secondary lh-base my-4 fix-testie-content testie-ellipsis">
                        {t.description}
                      </p>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="text-start">
                          <h4 className="font-weight-semibold font-size-16 mb-1 text-dark text-capitalize">{t.username}</h4>
                          <div className="text-secondary font-size-15">{t.designation}</div>
                        </div>
                        <div className="star">
                          <ReactStars
                            count={5}
                            edit={false}
                            size={16}
                            value={t.rating}
                            color2={'#ffd700'} />
                          {/* <img src="images/star.png" className="img-fluid" /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>

          {
            !ispage && <div className="row mt-4 mt-md-5 pt-2 pt-md-0">
              <div className="col-12 text-center">
                <Link to="/testimonials" className="btn btn-danger font-weight-semibold font-size-16 rounded-pill py-2"><span className="d-block py-md-1 px-3">View All Testimonials <FontAwesomeIcon icon={faLongArrowAltRight} className='ms-2 font-size-12' />
                </span></Link>
              </div>
            </div>
          }
        </div>
      </section>
    </>
  )
}

export default Testimonials