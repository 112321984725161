import axios from 'axios';
import { END_POINTS } from '../constants/Constant';

export const FETCH_DATA_REQUEST = 'FETCH_TESTIMONIAL_CONTENT_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_TESTIMONIAL_CONTENT_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_TESTIMONIAL_CONTENT_FAILURE';

export const fetchDataRequest = () => ({
    type: FETCH_DATA_REQUEST,
});

export const fetchDataSuccess = (data) => ({
    type: FETCH_DATA_SUCCESS,
    payload: data,
});

export const fetchDataFailure = (error) => ({
    type: FETCH_DATA_FAILURE,
    payload: error,
});

export const fetchTestimonialContentData = () => {
    return async (dispatch) => {
        dispatch(fetchDataRequest());
        try {
            const response = await axios.get(END_POINTS.GET_TESTIMONIAL_CONTENT);
            //console.log(response.data.data,"-------------testimonial");
            dispatch(fetchDataSuccess(response.data.data));
        } catch (error) {
            dispatch(fetchDataFailure(error.message));
        }
    };
};