import { faBuilding, faLongArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux';
import { BASE_URL, s3imageUrl } from '../../constants/Constant';
import { Link, useNavigate } from 'react-router-dom';

const FeaturedProperty = () => {
  const navigate = useNavigate();

  const FeatheredList = useSelector((state) => state.featheredProperty);
  const home = useSelector((state) => state.home);
  const handleViewMore = (property) => {
    navigate(`/property/${property.url}`, { state: property });
  };

  // const selectedCity = JSON.parse(localStorage.getItem('selectedCity'));
  // const selectedPropertyType = JSON.parse(localStorage.getItem('selectedPropertyType'));
  // const selectedListingType = JSON.parse(localStorage.getItem('selectedListingType'));

  // const formatUrlParam = (param) => {
  //   return param
  //     .toLowerCase()
  //     .replace(/\s+/g, '-')
  //     .replace(/[^a-z0-9-]/g, '');
  // };

  // console.log(selectedCity, selectedListingType, selectedPropertyType, "okokkkokokokoko")
  // const formattedPtype = formatUrlParam(selectedPropertyType.name);
  // const formattedLtype = formatUrlParam(selectedListingType.name);
  // const formattedCity = formatUrlParam(selectedCity.name);

  // const dynamicUrl = `/${formattedPtype}/${formattedLtype}/in/${formattedCity}`;



  return (
    <>
      <section className="homepad bg-white pt-0">
        <div className="container position-relative">
          <div className="row">
            <div className="col-md-8 col-lg-8 col-xl-6 mx-auto text-center">
              <h2 className="text-dark font-weight-bold font-size-50 mb-3 mb-lg-4 line-hight-2">{home.data.newPropertyTitle}</h2>
              <p className="font-size-18 text-muted line-hight-4 font-weight-normal">{home.data.newPropertyDescription}</p>
            </div>
          </div>
          <div className="row">
            {FeatheredList.loading ? (
              <div>Loading...</div>
            ) : FeatheredList.error ? (
              <div>Error: {FeatheredList.error}</div>
            ) : (
              FeatheredList.data.map((featherProperty, index) => (
                <div className="col-12 col-md-6 col-lg-4 mt-3 mt-lg-4 properties-box">
                  <div className="bg-light text-capitalize line-hight-1 h-100 rounded p-0">
                    <div className="overflow-hidden position-relative rounded img-pro">
                      <a href="javascript:void(0)"
                        onClick={() => handleViewMore(featherProperty)}
                      >

                        {/* <img src={BASE_URL + '/' + featherProperty.images[0]} className="img-fluid rounded w-100" /> */}
                        <img src={s3imageUrl + '/' + featherProperty.images[0]} className="img-fluid rounded w-100" />

                      </a>
                      {
                        featherProperty.isSoldOut
                          ?
                          <div className="properties-lable"><div className="tag-for font-weight-medium">Sold</div></div>
                          :
                          <div className="properties-lable"><div className="for-sale font-weight-medium">{featherProperty.listingType.name}</div></div>

                      }
                      {
                        featherProperty.isFeatured && <div className="featured-ribbon"><span>Featured</span></div>
                      }

                    </div>
                    <div className="p-4">
                      <h3 className="mb-0"><a className="font-size-22 text-dark font-weight-semibold" href="javascript:void(0)" onClick={() => handleViewMore(featherProperty)}>{featherProperty.title}</a></h3>
                      <div className="d-flex justify-content-between my-2 py-1">
                        <div className="text-secondary font-size-15">
                          <h6 className="font-weight-medium font-size-16 mb-1 text-dark">Location:</h6>{featherProperty.city.name}
                        </div>
                        <div className="text-secondary font-size-15 px-2">
                          <h6 className="font-weight-medium font-size-16 mb-1 text-dark">Size:</h6>{featherProperty.size}
                        </div>
                        <div className="text-secondary font-size-15">
                          <h6 className="font-weight-medium font-size-16 mb-1 text-dark">Price:</h6>{featherProperty.price}
                        </div>
                      </div>
                      <div className="text-secondary font-size-15 d-flex align-items-center ">
                        <div className="text-secondary font-size-15">
                          <h6 className="font-weight-medium font-size-16 mb-1 text-dark">Area:</h6>{featherProperty.location.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}



          </div>
          <div className="row mt-4 mt-md-5 pt-2 pt-md-0">
            <div className="col-12 text-center">
              <Link to={'/properties/all'} className="btn btn-danger font-weight-semibold font-size-16 rounded-pill py-2"><span className="d-block py-md-1 px-3">View All Properties<FontAwesomeIcon icon={faLongArrowRight} className='ms-2' /></span></Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default FeaturedProperty