// // reducers/dataReducer.js
// import {
//     FETCH_DATA_REQUEST,
//     FETCH_DATA_SUCCESS,
//     FETCH_DATA_FAILURE,
//   } from '../actions/blogActions';
  
//   const initialState = {
//     loading: false,
//     data: [],
//     error: null,
//   };
  
//   const builderReducer = (state = initialState, action) => {
//     switch (action.type) {
//       case FETCH_DATA_REQUEST:
//         return {
//           ...state,
//           loading: true,
//         };
//       case FETCH_DATA_SUCCESS:
//         return {
//           loading: false,
//           data: action.payload,
//           error: null,
//         };
//       case FETCH_DATA_FAILURE:
//         return {
//           loading: false,
//           data: [],
//           error: action.payload,
//         };
//       default:
//         return state;
//     }
//   };
  
//   export default builderReducer;
import {
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
} from '../actions/blogActions';

const initialState = {
  loading: false,
  blogs: [],  // Initial state for blogs
  error: null,
};

const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        blogs: action.append
          ? [...state.blogs, ...action.payload.blogs]  // Append if `append` is true
          : action.payload.blogs,  // Replace if `append` is false (i.e., first page)
        error: null,
      };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default blogReducer;
