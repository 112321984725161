import {
  faLongArrowAltRight,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../constants/Constant";

const Partner = () => {
  const home = useSelector((state) => state.home);

  const partners = useSelector((state) => state.partner);
  console.log(partners.data, "----------------*-*-*-*-*-");

  const options = {
    loop: false, // Disable looping
    responsiveClass: true,
    autoplayTimeout: 1000,
    nav: false,
    dots: true,
    responsive: {
      0: {
        items: 1, // Show 1 item on small screens
      },
      576: {
        items: 2, // Show 2 items on medium screens
      },
      992: {
        items: 3, // Adjust to 2 items to match your data
      },
    },
  };

  return (
    <>
      <section className="homepad bg-light">
        <div className="container">
          <div className="row mb-3 mb-lg-0 mb-lg-4">
            <div className="col-md-8 col-lg-7 col-xl-6 mx-auto text-center">
              <h2 className="text-dark font-weight-bold font-size-50 mb-3 text-capitalize line-hight-2">
                {home.data.partnerTitle}
              </h2>
              <p className="font-size-18 text-muted line-hight-3 mb-4 mb-md-3 font-weight-normal">
                {home.data.partnerDescription}
              </p>
            </div>
          </div>
          <div className="row">
            {/* <OwlCarousel options={options}> */}
            {partners.data.loading ? (
              <div>Loading...</div>
            ) : partners.data.error ? (
              <div>Error: {partners.error}</div>
            ) : (
              partners.data.map((p, index) => {
                console.log(p, "<---------------P");
                return (
                  <div className="col-6 " key={index}>
                    <div className="bg-white text-capitalize line-hight-1 h-100 rounded overflow-hidden">
                      <div className="overflow-hidden position-relative rounded img-pro">
                        <a href="javascript:void(0)">
                          <img
                            src={BASE_URL + "/" + p.image}
                            className="img-fluid rounded w-100 p-img"
                            alt={p.title}
                          />
                        </a>
                      </div>
                      <h3 className="mb-0">
                        <a
                          className="font-size-20 btn btn-danger w-100 p-2 p-md-3 text-center font-weight-semibold rounded"
                          href="javascript:void(0)"
                        >
                          {p.title}
                        </a>
                      </h3>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Partner;
