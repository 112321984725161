// reducers/index.js
import { combineReducers } from 'redux';
import cityReducer from './cityReducer';
import propertyTypeReducer from './propertyTypeReducer';
import listingTypeReducer from './listingTypeReducer';
import featherReducer from './featherReducer';
import soldReducer from './soldReducer';
import propertyListReducer from './propertyListReducer';
import footerReducer from './footerReducer';
import homeReducer from './homeReducer';
import whyUsReducer from './whyUsReducer';
import bannerReducer from './bannerReducer';
import testimonialReducer from './testimonialReducer';
import partnerReducer from './partnerReducer';
import builderReducer from './builderReducer';
import aboutReducer from './aboutReducer';
import teamReducer from './teamReducer';
import inquiryReducer from './inquiryReducer';
import termsReducer from './termsReducer';
import pricavyReducer from './pricavyReducer';
import contactReducer from './contactReducer';
import testimonialContentReducer from './testimonialContentReducer';
import propertyByURLReducer from './propertyByURLReducer';
import blogReducer from './blogReducer';

const rootReducer = combineReducers({
  // Add your reducers here
  city: cityReducer,
  propertyType: propertyTypeReducer,
  listingType: listingTypeReducer,
  featheredProperty: featherReducer,
  soldProperty: soldReducer,
  propertyList: propertyListReducer,
  headerFoooter: footerReducer,
  home: homeReducer,
  whyus: whyUsReducer,
  banner: bannerReducer,
  testimonial: testimonialReducer,
  partner: partnerReducer,
  builder: builderReducer,
  about: aboutReducer,
  team: teamReducer,
  inquiry: inquiryReducer,
  terms: termsReducer,
  privacy: pricavyReducer,
  contact: contactReducer,
  testimonialContent: testimonialContentReducer,
  propertyByURL: propertyByURLReducer,
  blogs: blogReducer,
});

export default rootReducer;