import { faLongArrowAltRight, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const About2 = () => {
  const home = useSelector((state) => state.home);

  return (
    <><section className="bg-white">
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-10 mx-auto">
            <div className="experiance_details font-size-17">
              <h2 className="text-dark font-weight-bold font-size-50 mb-4 line-hight-2">{home.data.about2Title}</h2>
              <p className="text-muted line-hight-4 font-weight-normal">
                
              <div dangerouslySetInnerHTML={{ __html: home.data.about2Description }} style={{ outline: "none" }}></div>
                </p>
              {/* <div className="experiance_button mt-4 pt-md-2">
                <Link to="/about-us" className="btn btn-danger font-weight-semibold font-size-16 rounded-pill py-2"><span className="d-block py-0 px-3">Read More<FontAwesomeIcon icon={faLongArrowAltRight} className='ms-2' /></span></Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default About2