import { faCheckCircle, faLongArrowAltRight, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux';

const Map = () => {
  // const content = useSelector((state) => state.headerFoooter);
  const contact = useSelector((state) => state.contact);
// console.log(content,"*-*-*-*-*-*-*-*");

  return (
    <>
      <section className="maps-mad">
        <img className='img-fluid w-100' src='/images/map.png'/>
        {/* <iframe src={contact.data.locationLink} width="100%" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
      </section>
    </>
  )
}

export default Map