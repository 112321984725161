import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom/dist';
import { fetchHomeData } from '../../actions/homeActions';
import { BASE_URL } from '../../constants/Constant';
import SearchBar from '../common/SearchBar';

const HeroSlider = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // //console.log(cityData, propertyTypeData,listingTypeData, "from inside");

  const cityData = useSelector((state) => state.city);
  const propertyTypeData = useSelector((state) => state.propertyType);
  const listingTypeData = useSelector((state) => state.listingType);
  const home = useSelector((state) => state.home);
  const banner = useSelector((state) => state.banner);

  useEffect(() => {


    // Get the selected values from localStorage
    const selectedCity = localStorage.getItem('selectedCity');
    const selectedPropertyType = localStorage.getItem('selectedPropertyType');
    const selectedListingType = localStorage.getItem('selectedListingType');

    // Set the selected values in the respective dropdowns
    const citySelect = document.querySelector('select[aria-label="city"]');
    const propertyTypeSelect = document.querySelector('select[aria-label="p-type"]');
    const listingTypeSelect = document.querySelector('select[aria-label="l-type"]');

    if (citySelect) {
      selectedCity === ""
        ?
        citySelect.value = ""
        :
        citySelect.value = JSON.parse(selectedCity)?._id || ''

    }

    if (propertyTypeSelect) {
      selectedPropertyType === ""
        ?
        propertyTypeSelect.value = ""
        :
        propertyTypeSelect.value = JSON.parse(selectedPropertyType)?._id || ''

    }

    if (listingTypeSelect) {
      selectedListingType === ""
        ?
        listingTypeSelect.value = ''
        :
        listingTypeSelect.value = JSON.parse(selectedListingType)?._id || ''

    }
  }, []);



  return (
    <>
      <section className="home-banner position-relative overflow-hidden">
        {/* <div id="myCarousel" className="carousel slide" data-bs-ride="carousel"> */}
        <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">

          <div className="carousel-indicators">
            {banner.data.filter(img => img.active).map((img, index) => (
              <button
                key={index}
                type="button"
                data-bs-target="#myCarousel"
                data-bs-slide-to={index}
                className={index === 0 ? 'active' : ''}
                aria-current={index === 0}
                aria-label={`Slide ${index + 1}`}
              />
            ))}
          </div>
          <div className="carousel-inner" data-bs-interval="1000">
            {banner.data.loading ? (
              <div>Loading...</div>
            ) : banner.data.error ? (
              <div>Error: {banner.error}</div>
            ) : (
              banner.data.filter(img => img.active).map((img, index) => (
                <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                  <img src={BASE_URL + '/' + img.image} className="img-fluid w-100 hero-banner" alt={img.image} />
                </div>
              ))
            )}
          </div>
          <div className="text-center carousel-caption">
            <h2 className="text-white font-weight-semibold text-uppercase font-size-22 mb-0 line-hight-3 findyour">{home.data.heroSubtitle}</h2>
            <h1 className="text-white font-size-70 my-3 my-lg-4 line-hight-1 font-weight-bold">{home.data.heroTitle} </h1>
            <p className="font-size-20 text-white line-hight-3 font-weight-normal">{home.data.heroDescription}</p>
            <SearchBar />
          </div>
        </div>
      </section>
    

    </>
  )
}

export default HeroSlider