import React, { lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { fetchCityData } from './actions/cityActions';
import { fetchPropertyTypeData } from './actions/propertyTypeActions';
import { fetchListingTypeData } from './actions/listingTypeActions';
import { fetchFeatherPropertyData } from './actions/featherActions';
import ScrollToTop from './components/common/ScrollToTop';
import { fetchHeaderFooterData } from './actions/footerActions';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import { fetchTermsData } from './actions/termsActions';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import FloatingButtons from './components/home/FloatingButton';
import BlogDetails from './pages/BlogDetails';

const Home = lazy(() => import('./pages/Home'));
const PropertyList = lazy(() => import('./pages/PropertyList'));
const PropertyDetail = lazy(() => import('./pages/PropertyDetail'));
const About = lazy(() => import('./pages/About'));
const Testimonial = lazy(() => import('./pages/Testimonial'));
const Blogs = lazy(() => import('./pages/Blogs'));
const Contact = lazy(() => import('./pages/Contact'));
const NotFound = lazy(() => import('./pages/NotFound'));

const Fallback = () => <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '75vh' }}>
  <div className="spinner-border" role="status" style={{ color: "#c72739" }}>
    <span className="visually-hidden">Loading...</span>
  </div>
</div>;

function App() {
  const dispatch = useDispatch();

  const listingType = useSelector((state) => state.listingType);

  useEffect(() => {
    dispatch(fetchHeaderFooterData());
    dispatch(fetchCityData());
    dispatch(fetchPropertyTypeData());
    dispatch(fetchListingTypeData());
    dispatch(fetchTermsData());



  }, [dispatch]);


  return (
    <Router>
      <ScrollToTop />
      <div>
        <Suspense fallback={<Fallback />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/properties/:dynamicURL" element={<PropertyList />} />
            <Route path="/property/:slug" element={<PropertyDetail />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/testimonials" element={<Testimonial />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogs/:slug" element={<BlogDetails/>} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/trems-and-condition" element={<Terms />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
        <FloatingButtons />
      </div>
    </Router>
  );
}

export default App;
library.add(fab, fas, far)