import { faLongArrowAltRight, faLongArrowRight, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const CTA = () => {
  const content = useSelector((state) => state.headerFoooter);
  return (
   <>
   <section className="py-5 position-relative bg-danger" style={{background:"url(images/downloadbrochure-bg.jpg) no-repeat", backgroundPosition: "center top", backgroundSize: "cover"}}>
     <div className="position-absolute downloadbrochure-img"><img src="images/downloadbrochure-img.png" alt="" className="img-fluid"/></div>
      <div className="container">
       <div className="row align-items-center">
         <div className="col-md text-center text-md-start">
           <h2 className="font-weight-bold text-capitalize text-white font-size-40 mb-0 line-hight-1">{content.data.titleCTA}</h2>
         </div>
         <div className="col-md-auto ms-auto text-center text-md-end pt-4 pt-md-0">
  <Link to={content.data.linkCTA}  className="btn btn-light py-2 font-weight-semibold rounded-pill">
    <span className="d-block px-3 px-lg-4 py-md-1">Click here <FontAwesomeIcon icon={faLongArrowRight} className='ms-2 font-size-12'/></span>
  </Link>
</div>
       </div>
     </div>
   </section>
   </>
  )
}

export default CTA