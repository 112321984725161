import { faLongArrowAltRight, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../constants/Constant';

const About1 = () => {
    const home = useSelector((state) => state.home);
    const content = useSelector((state) => state.headerFoooter);


    return (
        <>

            <section className="experiance homepad bg-red-light">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-6 order-1 order-lg-1">
                            <div className="experiance_images position-relative">
                                <div className="experiance_image1">
                                    <img src={BASE_URL + "/" + home.data.aboutImgOne} alt="" className="img-fluid w-100 rounded" />
                                </div>
                                <div className="experiance_image_cart d-none d-lg-flex">
                                    <div className="experiance_image_cart_body rounded overflow-hidden bg-white">
                                        <img src={BASE_URL + "/" + home.data.aboutImgTwo} alt="" className="img-fluid experiance_image2 bg-white p-2 p-lg-3" />
                                    </div>
                                </div>
                                <div className="experiance_image_button d-flex align-items-center rounded bg-white">
                                    <div className="experiance_icon">
                                        <FontAwesomeIcon icon={faPhone} className='text-white' />
                                    </div>
                                    <div className="experiance_icon_text">
                                        <span className="text-danger font-weight-medium font-size-14 ps-1 line-hight-1 d-block pb-1">Get In touch</span>
                                        <a href={`tel:${content.data.headerPhone}`} className="text-dark mb-0 font-weight-bold font-size-20 line-hight-1">{content.data.headerPhone}</a>
                                        {/* <a className="text-decoration-none text-dark">
                        {content.data.headerPhone}
                      </a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="experiance_button mt-4 mt-md-5 pt-2 pt-md-0 d-lg-none text-center">
                                <Link href="#" className="btn btn-danger font-weight-semibold font-size-16 rounded-pill py-2"><span className="d-block py-md-1 px-3"> Read More<FontAwesomeIcon icon={faLongArrowAltRight} className='ms-2 font-size-12' /></span></Link>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="experiance_details ps-lg-4 text-center text-lg-start">
                                <h5 className="text-danger font-weight-semibold text-uppercase font-size-20 mb-0 line-hight-1 findyour">{home.data.aboutSubtitle}</h5>
                                <h2 className="text-dark font-weight-bold font-size-50 my-4 line-hight-2">{home.data.aboutTitle}</h2>
                                <p className="font-size-16 text-muted line-hight-4 font-weight-normal">
                                    <div dangerouslySetInnerHTML={{ __html: home.data.aboutDescription }} style={{ outline: "none" }}></div>
                                </p>
                                <div className="experiance_button mt-4 pt-md-2 d-none d-lg-flex">
                                    <Link to="/about-us" className="btn btn-danger font-weight-semibold font-size-16 rounded-pill py-2"><span className="d-block py-0 px-3"> Read More<FontAwesomeIcon icon={faLongArrowAltRight} className='ms-2 font-size-12' /></span></Link>
                                </div>
                            </div>
                            {/* <div className="experiance-details-side-icon position-absolute d-none d-lg-block">
                        <img src="assets/img/about-shape.png" alt="" className="img-fluid"/>
                    </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About1