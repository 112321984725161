import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchHomeData } from '../../actions/homeActions';

const SearchBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cityData = useSelector((state) => state.city);
  const propertyTypeData = useSelector((state) => state.propertyType);
  const listingTypeData = useSelector((state) => state.listingType);

  // const defaultId = "666c26158a22a08d4ec67ac4";
  // const [listingTypeSelect, setListingTypeSelect] = useState(defaultId);
  const [cityValidationMsg, setCityValidationMsg] = useState(''); // State to manage city validation message

  useEffect(() => {
    localStorage.setItem('selectedCity', '');
    localStorage.setItem('selectedPropertyType', '');
    localStorage.setItem('selectedListingType', JSON.stringify({_id: "666c26158a22a08d4ec67ac4", name: "For Sale", __v: 0, isActive: true}));
    
  
  }, []);


  useEffect(() => {
    dispatch(fetchHomeData());
    // handleListingTypeChange
    const citySelect = document.querySelector('select[aria-label="city"]');
    const propertyTypeSelect = document.querySelector('select[aria-label="p-type"]');
    const listingTypeSelect = document.querySelector('select[aria-label="l-type"]');

    const selectedCity = localStorage.getItem('selectedCity');
    const selectedPropertyType = localStorage.getItem('selectedPropertyType');
    const selectedListingType = localStorage.getItem('selectedListingType');

    if (citySelect) {
      citySelect.value = selectedCity ? (JSON.parse(selectedCity)?._id || '') : '';
    }

    if (propertyTypeSelect) {
      propertyTypeSelect.value = selectedPropertyType ? (JSON.parse(selectedPropertyType)?._id || '') : '';
    }

    if (listingTypeSelect) {
      listingTypeSelect.value = selectedListingType ? (JSON.parse(selectedListingType)?._id || '666c26158a22a08d4ec67ac4') : '666c26158a22a08d4ec67ac4';
    }
  }, [dispatch]);

  const handleCityChange = (e) => {
    const selectedCityId = e.target.value;
    if (selectedCityId === "") {
      localStorage.setItem('selectedCity', '');
    } else {
      const selectedCity = cityData.data.find((city) => city._id === selectedCityId);
      localStorage.setItem('selectedCity', JSON.stringify(selectedCity));
      setCityValidationMsg(''); // Clear validation message if city is selected
    }
  };

  const handlePropertyTypeChange = (e) => {
    const selectedTypeId = e.target.value;
    if (selectedTypeId === "") {
      localStorage.setItem('selectedPropertyType', '');
    } else {
      const selectedPropertyType = propertyTypeData.data.find((p) => p._id === selectedTypeId);
      localStorage.setItem('selectedPropertyType', JSON.stringify(selectedPropertyType));
    }
  };

  const handleListingTypeChange = (e) => {
    const selectedLTypeId = e.target.value;
    // setListingTypeSelect(e.target.value);
    if (selectedLTypeId === "") {
      localStorage.setItem('selectedListingType', '');
    } else {
      const selectedListingType = listingTypeData.data.find((l) => l._id === selectedLTypeId);
      localStorage.setItem('selectedListingType', JSON.stringify(selectedListingType));
    }
  };

  const handleSearch = () => {
    const selectedCity = localStorage.getItem('selectedCity') ? JSON.parse(localStorage.getItem('selectedCity')) : null;

    if (!selectedCity) {
      setCityValidationMsg('Please select a city.'); // Show validation message if city is not selected
      return;
    }

    const selectedPropertyType = localStorage.getItem('selectedPropertyType') ? JSON.parse(localStorage.getItem('selectedPropertyType')) : null;
    const selectedListingType = localStorage.getItem('selectedListingType') ? JSON.parse(localStorage.getItem('selectedListingType')) : null;

    const formatUrlParam = (param) => {
      return param
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^a-z0-9-]/g, '');
    };

    const formattedPtype = formatUrlParam(selectedPropertyType ? selectedPropertyType.name : 'properties');
    const formattedLtype = formatUrlParam(selectedListingType ? selectedListingType.name : '');
    const formattedCity = formatUrlParam(selectedCity ? selectedCity.name : '');

    const dynamicUrl = `/properties/${formattedPtype}${formattedLtype ? `-${formattedLtype}` : ''}-in-${formattedCity}`;
    navigate(dynamicUrl);
  };

  return (
    <div className="form-bg bg-white p-4 mt-4 mt-lg-5 mb-4">
      <div className="row genuine-university row-cols-1 row-cols-sm-2 row-cols-md-4 g-3 g-lg-3">
        <div className="col mb-0 select-drop-col">
          {cityData.loading ? (
            <div>Loading...</div>
          ) : cityData.error ? (
            <div>Error: {cityData.error}</div>
          ) : (
            <>
              <select
                className="form-select form-control rounded-pill border-0 bg-light ps-4 font-weight-normal"
                aria-label="city"
                onChange={handleCityChange}
              >
                <option value="">City</option>
                {cityData.data.map((city, index) => (
                  <option key={index} value={city._id}>
                    {city.name}
                  </option>
                ))}
              </select>
              {cityValidationMsg && <div className="text-danger mt-1">{cityValidationMsg}</div>} {/* Validation message */}
            </>
          )}
        </div>
        <div className="col mb-0 select-drop-col">
          {propertyTypeData.loading ? (
            <div>Loading...</div>
          ) : propertyTypeData.error ? (
            <div>Error: {propertyTypeData.error}</div>
          ) : (
            <select
              className="form-select form-control rounded-pill border-0 bg-light ps-4 font-weight-normal"
              aria-label="p-type"
              onChange={handlePropertyTypeChange}
            >
              <option value="">Property Type</option>
              {propertyTypeData.data.map((type, index) => (
                <option key={index} value={type._id}>
                  {type.name}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className="col mb-0 select-drop-col">
          {listingTypeData.loading ? (
            <div>Loading...</div>
          ) : listingTypeData.error ? (
            <div className='text-danger'>Error: {listingTypeData.error}</div>
          ) : (
            <select
              className="form-select form-control rounded-pill border-0 bg-light ps-4 font-weight-normal"
              aria-label="l-type"
              onChange={handleListingTypeChange}
              // defaultValue={listingTypeData.length !== 0 ? '666c26158a22a08d4ec67ac4' : ''}
              // defaultValue={localStorage.getItem('selectedListingType')?._id || ''}
            >
              <option value="">Buy/Rent</option>
              {listingTypeData.data.map((ltype, index) => (
                <option key={index} value={ltype._id}>
                  {ltype.name}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className="col mb-0 search-col">
          <button
            onClick={handleSearch}
            type="button"
            className="btn btn-danger w-100 font-weight-bold font-size-16 rounded-pill py-2"
          >
            <span className="d-block py-0 px-3">
              Search <FontAwesomeIcon icon={faLongArrowAltRight} className='ms-2' />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
