import { faBuilding, faHandshake, faHome, faMoneyBill, faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../constants/Constant';

const WhyUs = () => {
  const home = useSelector((state) => state.home);

  const whyData = useSelector((state) => state.whyus);

  return (
    <>
      <section className="homepad whychooseus position-relative" style={{ background: "url(images/whychooseus-img.jpg) no-repeat", backgroundPosition: "center", backgroundSize: "cover" }}>
        <div className="container position-relative">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <h2 className="text-white font-weight-bold font-size-50 mb-3 mb-lg-4 line-hight-3">{home.data.whyTitle}</h2>
            </div>
          </div>

          <div className="row">

            {whyData.data.loading ? (
              <div>Loading...</div>
            ) : whyData.data.error ? (
              <div>Error: {whyData.error}</div>
            ) : (

              whyData.data.map((why, index) => (
                <div className="col-12 col-md-6 col-lg-4 mt-4">
                  <div className="bg-white p-4 rounded h-100 text-center whychoose-box">
                    <div className="pb-3">
                      <div className="whychoose-icon rounded-circle mx-auto my-4 my-lg-5 d-flex justify-content-center align-items-center">
                        {/* <FontAwesomeIcon icon={faHandshake} className='text-danger font-size-20' style={{fontSize: "46px"}}/> */}
                        {/* <img src={BASE_URL+'/'+why.icon} className="img-fluid"/> */}
                        <i className={`fas ${why.icon} icon`} style={{ fontSize: "36px" }} color='#c72739' ></i>
                      </div>
                      <h3 className="mb-4 font-size-20 text-danger font-weight-bold line-hight-1">{why.title}</h3>
                      <p className="mb-0 text-secondary font-weight-normal font-size-16 line-hight-4 testie-ellipsis fix-testie-content">{why.description}</p>
                    </div>
                  </div>
                </div>
              ))

            )}



            {/* 
          <div className="col-12 col-md-6 col-lg-4 mt-4">
            <div className="bg-white p-4 rounded h-100 text-center whychoose-box">
              <div className="pb-3">
                <div className="whychoose-icon rounded-circle mx-auto my-4 my-lg-5 d-flex justify-content-center align-items-center"> <FontAwesomeIcon icon={faHome} className='text-danger font-size-20' style={{fontSize: "46px"}}/>
                </div>
                <h3 className="mb-4 font-size-20 text-danger font-weight-bold line-hight-1">Wide Renge Of Properties</h3>
                <p className="mb-0 text-secondary font-weight-normal font-size-16 line-hight-4">It is a long established fact that a reader will be distracted by fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
              </div>
            </div>
          </div> */}
            {/*           
          <div className="col-12 col-md-6 col-lg-4 mt-4">
            <div className="bg-white p-4 rounded h-100 text-center whychoose-box">
              <div className="pb-3">
                <div className="whychoose-icon rounded-circle mx-auto my-4 my-lg-5 d-flex justify-content-center align-items-center"><FontAwesomeIcon icon={faMoneyBill1Wave} className='text-danger font-size-20' style={{fontSize: "46px"}}/>
                </div>
                <h3 className="mb-4 font-size-20 text-danger font-weight-bold line-hight-1">Financing Made Easy</h3>
                <p className="mb-0 text-secondary font-weight-normal font-size-16 line-hight-4">It is a long established fact that a reader will be distracted by fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mt-4">
            <div className="bg-white p-4 rounded h-100 text-center whychoose-box">
              <div className="pb-3">
                <div className="whychoose-icon rounded-circle mx-auto my-4 my-lg-5 d-flex justify-content-center align-items-center"> <FontAwesomeIcon icon={faHandshake} className='text-danger font-size-20' style={{fontSize: "46px"}}/>
                </div>
                <h3 className="mb-4 font-size-20 text-danger font-weight-bold line-hight-1">Trusted By Thousands</h3>
                <p className="mb-0 text-secondary font-weight-normal font-size-16 line-hight-4">It is a long established fact that a reader will be distracted by fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4 mt-4">
            <div className="bg-white p-4 rounded h-100 text-center whychoose-box">
              <div className="pb-3">
                <div className="whychoose-icon rounded-circle mx-auto my-4 my-lg-5 d-flex justify-content-center align-items-center"><FontAwesomeIcon icon={faHome} className='text-danger font-size-20' style={{fontSize: "46px"}}/>
                </div>
                <h3 className="mb-4 font-size-20 text-danger font-weight-bold line-hight-1">Wide Renge Of Properties</h3>
                <p className="mb-0 text-secondary font-weight-normal font-size-16 line-hight-4">It is a long established fact that a reader will be distracted by fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
              </div>
            </div>
          </div>
          
          <div className="col-12 col-md-6 col-lg-4 mt-4">
            <div className="bg-white p-4 rounded h-100 text-center whychoose-box">
              <div className="pb-3">
                <div className="whychoose-icon rounded-circle mx-auto my-4 my-lg-5 d-flex justify-content-center align-items-center"><FontAwesomeIcon icon={faMoneyBill1Wave} className='text-danger font-size-20' style={{fontSize: "46px"}}/>
                </div>
                <h3 className="mb-4 font-size-20 text-danger font-weight-bold line-hight-1">Financing Made Easy</h3>
                <p className="mb-0 text-secondary font-weight-normal font-size-16 line-hight-4">It is a long established fact that a reader will be distracted by fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      </section>
    </>
  )
}

export default WhyUs