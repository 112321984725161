import { faLongArrowAltRight, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../constants/Constant';

const TopBuilders = () => {
  const home = useSelector((state) => state.home);
  const builder = useSelector((state) => state.builder);
  console.log(builder, "this is builders")
  return (
    <><section className="homepad bg-red-light">
      <div className="container">
        <div className="row mb-4">
          <div className="col-sm-9 col-md-9 col-lg-7 text-center mx-auto">
            <h2 className="text-dark font-weight-bold font-size-50 mb-0 line-hight-2">{home.data.topBuildersTitle}</h2>
          </div>

        </div>
        <div className="row clientlogo">
          {builder.data.loading ? (
            <div>Loading...</div>
          ) : builder.data.error ? (
            <div>Error: {builder.error}</div>
          ) : (
            builder.data.map((b, index) => (
              <div className="col my-2 my-sm-3 my-lg-4">
                <div className="p-1 border bg-white shadow-sm">
                  <img src={BASE_URL + '/' + b.logoUrl} className="img-fluid w-100 h-150" />
                </div>
              </div>
            ))
          )}


        </div>
      </div>
    </section>
    </>
  )
}

export default TopBuilders