// import React, { useEffect, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
// import { addInquiryData } from '../../actions/inquiryActions';
// import { fetchContactData } from '../../actions/contactActions';

// const ContactForm = () => {
//   const dispatch = useDispatch();
//   const propertyTypeData = useSelector((state) => state.propertyType);
//   const inquiryState = useSelector((state) => state.inquiry);
//   const contact = useSelector((state) => state.contact);

//   useEffect(() => {
//     dispatch(fetchContactData());

//   }, [dispatch]);


//   const [formData, setFormData] = useState({
//     name: '',
//     mobile: '',
//     propertyType: '',
//     message: '',
//     city
//   });

//   // Handle input change
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   // Handle form submission
//   const handleSubmit = (e) => {
//     e.preventDefault();

//     dispatch(addInquiryData(formData));
//     console.log(inquiryState, "this one is ok")
//   };
//   useEffect(() => {
//     if (inquiryState.data) {
//       setFormData({
//         name: '',
//         mobile: '',
//         propertyType: '',
//         message: ''
//         city:''
//       });
//     }
//   }, [inquiryState.data]);
//   return (
//     <>
//       <section className="homepad bg-white">
//         <div className="container">
//           <div className="row mb-3 mb-lg-4">
//             <div className="col-md-8 col-lg-7 col-xl-6 mx-auto fw-medium line-hight-3 text-center text-secondary font-size-16">
//               <h3 className="mb-3 line-hight-1 font-weight-semibold font-size-50 text-dark">{contact.data?.contactTitle}</h3>
//               <p>{contact.data?.contactDescription} </p>
//             </div>
//           </div>

//           <form onSubmit={handleSubmit}>
//             <div className="row">
//               <div className="col-lg-10 mx-auto">
//                 <div className="row">
//                   <div className="col-md-6">
//                     <div className="mb-3">
//                       <input
//                         type="text"
//                         name="name"
//                         className="form-control rounded-pill border-0 bg-light ps-4 font-weight-normal"
//                         placeholder="Name"
//                         value={formData.name}
//                         onChange={handleChange}
//                       />
//                     </div>
//                     <div className="mb-3">
//                       <input
//                         type="number"
//                         name="mobile"
//                         className="form-control rounded-pill border-0 bg-light ps-4 font-weight-normal"
//                         placeholder="Mobile No"
//                         value={formData.mobile}
//                         onChange={handleChange}
//                       />
//                     </div>
//                     <div className="mb-3">
//                       {propertyTypeData.data.loading ? (
//                         <div>Loading...</div>
//                       ) : propertyTypeData.data.error ? (
//                         <div>Error: {propertyTypeData.error}</div>
//                       ) : (
//                         <select
//                           name="propertyType"
//                           className="form-select form-control rounded-pill border-0 bg-light ps-4 font-weight-normal"
//                           aria-label="p-type"
//                           value={formData.propertyType}
//                           onChange={handleChange}
//                         >
//                           <option value="">Property Type</option>
//                           {propertyTypeData.data.map((type, index) => (
//                             <option key={index} value={type._id}>
//                               {type.name}
//                             </option>
//                           ))}
//                         </select>
//                       )}
//                     </div>
//                   </div>
//                   <div className="col-md-6">
//                     <div className="mb-3">
//                       <textarea
//                         name="message"
//                         className="form-control border-0 bg-light ps-4 py-3 font-weight-normal contact-form-massage"
//                         placeholder="Your message"
//                         rows="5"
//                         value={formData.message}
//                         onChange={handleChange}
//                       ></textarea>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="row mt-3">
//               <div className="col-md-12">
//                 <div className="request_quote_button text-center">
//                   <button type="submit" className="btn btn-danger font-weight-bold font-size-16 rounded-pill py-2 px-5">
//                     <span className="d-block py-0 px-3">
//                       Submit
//                       <FontAwesomeIcon icon={faLongArrowAltRight} className="ms-2" />
//                     </span>
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </form>

//           {inquiryState.loading && (
//             <div className="row mt-3">
//               <div className="col-md-12 text-center">
//                 <div className="alert alert-info">Submitting...</div>
//               </div>
//             </div>
//           )}
//           {inquiryState.error && (
//             <div className="row mt-3">
//               <div className="col-md-12 text-center">
//                 <div className="alert alert-danger">{inquiryState.error}</div>
//               </div>
//             </div>
//           )}
//           {inquiryState.data.length !== 0 && (
//             <div className="row mt-3">
//               <div className="col-md-12 text-center">
//                 <div className="alert alert-success">Inquiry submitted successfully!</div>
//               </div>
//             </div>
//           )}
//         </div>
//       </section>
//     </>
//   );
// }

// export default ContactForm;
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { addInquiryData } from '../../actions/inquiryActions';
import { fetchContactData } from '../../actions/contactActions';
import { fetchCityData } from '../../actions/cityActions'; // Assuming you have a city action to fetch city data

const ContactForm = () => {
  const dispatch = useDispatch();
  const propertyTypeData = useSelector((state) => state.propertyType);
  const inquiryState = useSelector((state) => state.inquiry);
  const contact = useSelector((state) => state.contact);
  const cityData = useSelector((state) => state.city); // Assuming you have city data in your state

  useEffect(() => {
    dispatch(fetchContactData());
    dispatch(fetchCityData()); // Fetch city data on component mount
  }, [dispatch]);

  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    propertyType: '',
    message: '',
    city: '' // Initialize city field
  });

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addInquiryData(formData));
    console.log(inquiryState, "this one is ok")
  };

  useEffect(() => {
    if (inquiryState.data) {
      setFormData({
        name: '',
        mobile: '',
        propertyType: '',
        message: '',
        city: '' // Reset city field
      });
    }
  }, [inquiryState.data]);

  return (
    <>
      <section className="homepad bg-white">
        <div className="container">
          <div className="row mb-3 mb-lg-4">
            <div className="col-md-8 col-lg-7 col-xl-6 mx-auto fw-medium line-hight-3 text-center text-secondary font-size-16">
              <h3 className="mb-3 line-hight-1 font-weight-semibold font-size-50 text-dark">{contact.data?.contactTitle}</h3>
              <p>{contact.data?.contactDescription}</p>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <input
                        type="text"
                        name="name"
                        className="form-control rounded-pill border-0 bg-light ps-4 font-weight-normal"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="number"
                        name="mobile"
                        className="form-control rounded-pill border-0 bg-light ps-4 font-weight-normal"
                        placeholder="Mobile No"
                        value={formData.mobile}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      {propertyTypeData.data.loading ? (
                        <div>Loading...</div>
                      ) : propertyTypeData.data.error ? (
                        <div>Error: {propertyTypeData.error}</div>
                      ) : (
                        <select
                          name="propertyType"
                          className="form-select form-control rounded-pill border-0 bg-light ps-4 font-weight-normal"
                          aria-label="p-type"
                          value={formData.propertyType}
                          onChange={handleChange}
                        >
                          <option value="">Property Type</option>
                          {propertyTypeData.data.map((type, index) => (
                            <option key={index} value={type._id}>
                              {type.name}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                    <div className="mb-3">
                      {cityData.loading ? (
                        <div>Loading...</div>
                      ) : cityData.error ? (
                        <div>Error: {cityData.error}</div>
                      ) : (
                        <select
                          name="city"
                          className="form-select form-control rounded-pill border-0 bg-light ps-4 font-weight-normal"
                          aria-label="city"
                          value={formData.city}
                          onChange={handleChange}
                        >
                          <option value="">Your current location</option>
                          {cityData.data.map((city, index) => (
                            <option key={index} value={city._id}>
                              {city.name}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <textarea
                        name="message"
                        className="form-control border-0 bg-light ps-4 py-3 font-weight-normal contact-form-massage"
                        placeholder="Your message"
                        rows="7"
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <div className="request_quote_button text-center">
                  <button type="submit" className="btn btn-danger font-weight-bold font-size-16 rounded-pill py-2 px-5">
                    <span className="d-block py-0 px-3">
                      Submit
                      <FontAwesomeIcon icon={faLongArrowAltRight} className="ms-2" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </form>

          {inquiryState.loading && (
            <div className="row mt-3">
              <div className="col-md-12 text-center">
                <div className="alert alert-info">Submitting...</div>
              </div>
            </div>
          )}
          {inquiryState.error && (
            <div className="row mt-3">
              <div className="col-md-12 text-center">
                <div className="alert alert-danger">{inquiryState.error}</div>
              </div>
            </div>
          )}
          {inquiryState.data.length !== 0 && (
            <div className="row mt-3">
              <div className="col-md-12 text-center">
                <div className="alert alert-success">Inquiry submitted successfully!</div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default ContactForm;

