import axios from 'axios';
import { END_POINTS } from '../constants/Constant';

export const FETCH_DATA_REQUEST = 'FETCH_INQUIRY_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_INQUIRY_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_INQUIRY_FAILURE';

export const fetchDataRequest = () => ({
  type: FETCH_DATA_REQUEST,
});

export const fetchDataSuccess = (data) => ({
  type: FETCH_DATA_SUCCESS,
  payload: data,
});

export const fetchDataFailure = (error) => ({
  type: FETCH_DATA_FAILURE,
  payload: error,
});

export const addInquiryData = (data) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      const response = await axios.post(END_POINTS.ADD_INQUIRY, data);
      dispatch(fetchDataSuccess(response.data.data));

    } catch (error) {
      if (error.response?.data?.message) {
        dispatch(fetchDataFailure(error.response?.data?.message));
      } else {
        dispatch(fetchDataFailure(error.message));
      }
    }
  };
};