import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

const FloatingButtons = () => {
  const content = useSelector((state) => state.headerFoooter);

  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div style={floatingButtonStyles.container}>
      {/* WhatsApp Button */}
      <a
        href={`https://wa.me/${content.data.headerPhone}`} // Replace with your WhatsApp number
        target="_blank"
        rel="noopener noreferrer"
        style={floatingButtonStyles.button1}
      >
        <FontAwesomeIcon icon={['fab', 'whatsapp']} size="2x" style={floatingButtonStyles.icon} />
      </a>

      {/* Call Now Button */}
      <a href={`tel:${content.data.headerPhone}`} style={floatingButtonStyles.button}> {/* Replace with your number */}
        <FontAwesomeIcon icon={['fas', 'phone']} size="2x" style={floatingButtonStyles.icon} />
      </a>

      {/* Scroll to Top Button */}
      {showScrollButton && (
        <button onClick={scrollToTop} style={floatingButtonStyles.button}>
          <FontAwesomeIcon icon={['fas', 'arrow-up']} size="2x" style={floatingButtonStyles.icon} />
        </button>
      )}
    </div>
  );
};

const floatingButtonStyles = {
  container: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    zIndex: 1000,
  },
  button: {
    backgroundColor: '#c72739',
    borderRadius: '50%',
    padding: '10px',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '50px',
  },
  button1: {
    backgroundColor: '#25d366',
    borderRadius: '50%',
    padding: '10px',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '50px',
  },
  icon: {
    color: '#fff',
  },
};

export default FloatingButtons;
