import axios from 'axios';
import { END_POINTS } from '../constants/Constant';

export const FETCH_DATA_REQUEST = 'FETCH_CONTACT_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_CONTACT_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_CONTACT_FAILURE';

export const fetchDataRequest = () => ({
    type: FETCH_DATA_REQUEST,
});

export const fetchDataSuccess = (data) => ({
    type: FETCH_DATA_SUCCESS,
    payload: data,
});

export const fetchDataFailure = (error) => ({
    type: FETCH_DATA_FAILURE,
    payload: error,
});

export const fetchContactData = () => {
    return async (dispatch) => {
        dispatch(fetchDataRequest());
        try {
            const response = await axios.get(END_POINTS.GET_CONTACT);
            //console.log(response.data.data,"-------------about");
            dispatch(fetchDataSuccess(response.data.data));
        } catch (error) {
            dispatch(fetchDataFailure(error.message));
        }
    };
};