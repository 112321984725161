import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../constants/Constant';

const Category = () => {

  const cityData = useSelector((state) => state.city.data);
  const propertyTypeData = useSelector((state) => state.propertyType.data);
  const navigate = useNavigate();
  const home = useSelector((state) => state.home);
  console.log(home, "this is homedata")
  
  const handleClick = async(c) => {
    console.log(c, "c");
    const { propertyType, listingType, city } = await extractDetails(c.url); 
    
    navigate(`/properties${c.url}`, {
      replace: true,
      state: { propertyDetails: { ...c, propertyType: propertyType,
          listingType: listingType,
          city: city } } 
  });
  };

  function extractDetails(inputString) {
    const lowerCasedString = inputString.toLowerCase();
  
    const parts = lowerCasedString.split('-');

    const listingTypeIndex = parts.findIndex(part => part === 'for' && (parts[parts.indexOf(part) + 1] === 'sale' || parts[parts.indexOf(part) + 1] === 'rent'));
    
    let listingType = null;
    if (listingTypeIndex !== -1) {
        listingType = `for ${parts[listingTypeIndex + 1]}`;
    }

    let propertyType = listingTypeIndex !== -1 ? parts.slice(0, listingTypeIndex).join(' ') : null;
    propertyType = propertyType ? propertyType.replace(/\//g, ' ').trim() : null; 

    let city = listingTypeIndex !== -1 ? parts.slice(listingTypeIndex + 2).join(' ') : null;
    city = city ? city.replace(/\bin\b/g, '').trim() : null; 
    
    return {
        propertyType: propertyType ? capitalize(propertyType) : null,
        listingType: listingType ? capitalize(listingType) : null,
        city: city ? capitalize(city) : null,
    };
}

function capitalize(str) {
    return str.replace(/\b\w/g, char => char.toUpperCase());
}
  return (
    <>
      <section className="homepad building-section position-relative">
        <div className="container">
          <div className="row row-cols-2 row-cols-sm-3 row-cols-md-5 g-3 justify-content-center">
            {home.data?.category?.slice(0, 5).map((c, index) => {
              return (
                <div
                  key={index}
                  className="col"
                  onClick={() => handleClick(c)}
                >
                  <div className="bg-white p-4 rounded h-100 text-center building-box shadow-sm c-pointer">
                    <div className="p-lg-3">
                      <div className="building-icon rounded">
                        <i className={`fas ${c.icon} icon`} style={{ fontSize: "36px" }} color='primary' ></i>
                      </div>
                      <h3 className="my-2 pt-2 pt-lg-4 pb-lg-2 font-size-18 text-danger font-weight-semibold line-hight-1">
                        {c.title}
                      </h3>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  )
}

export default Category